<template>
  <div class="orderAdd">
    <div class="procedure-detail-header">
      <div class="left" @click="$router.back()">
        <img src="@/assets/information/procedure/左滑@2x.png">
        <span>探伤检测任务详情</span>
      </div>
    </div>
    <div class="detail-card">
      <div class="card-tit">基本信息</div>
      <div class="card-bot">
        <el-col :span="6">
          <span class="bot-ti">任务编号：{{ detailData.code }}</span>
        </el-col>
        <el-col :span="6">
          <span class="bot-ti">业务类型：{{ inspectionTypeNames[detailData.type] }}</span>
        </el-col>
        <el-col :span="6">
          <span class="bot-ti">质检方案：{{ detailData.inspectionSchemeName }}</span>
        </el-col>
        <el-col :span="6">
          <span class="bot-ti">质检状态：{{
              detailData.status || detailData.status == 0 ? qualityTaskStatus[detailData.status].name : ''
            }}</span>
        </el-col>
        <el-col :span="6">
          <span class="bot-ti">生产工单：{{ detailData.producePlanNumber }}</span>
        </el-col>
        <el-col :span="6">
          <span class="bot-ti">生产产品：{{ detailData.materialsFullName }}</span>
        </el-col>
        <el-col :span="6">
          <span class="bot-ti">生产任务：{{ detailData.productionTaskCode }}</span>
        </el-col>
        <el-col :span="6">
          <span class="bot-ti">工序：{{ detailData.procedureName }}</span>
        </el-col>
        <el-col :span="6">
          <span class="bot-ti">创建人：{{ detailData.createUser }}</span>
        </el-col>
        <el-col :span="6">
          <span class="bot-ti">创建时间：{{ detailData.createTime }}</span>
        </el-col>
        <el-col :span="6">
          <span class="bot-ti">任务开始时间：{{ detailData.startTime }}</span>
        </el-col>
        <el-col :span="6">
          <span class="bot-ti">任务结束时间：{{ detailData.endTime }}</span>
        </el-col>
        <el-col :span="6">
          <span class="bot-ti">执行人：{{ detailData.executorName }}</span>
        </el-col>
        <el-col :span="6">
          <span class="bot-ti">业务部门：{{ detailData.businessUnitName }}</span>
        </el-col>
        <el-col :span="6" v-if="type === 'view' || type === 'edit'">
          <span class="bot-ti">结论判定：
            <span :style="{color:detailData.result == 0 ? '#E02020':detailData.result == 1 ? '#00AB29':''}">
              {{ detailData.result == 0 ? '不合格' : detailData.result == 1 ? '合格' : '' }}
            </span>
          </span>
        </el-col>
        <!-- 仅在结论判定未不合格时显示 -->
        <el-col :span="6" v-if="detailData.result == 0 && (type === 'view' || type === 'edit')">
          <span class="bot-ti">处理意见：{{ detailData.suggestion }}</span>
        </el-col>
        <el-col :span="6" v-if="type === 'view' || type === 'edit'">
          <span class="bot-ti">附件：
            <el-link v-for="item in detailData.attachment" :key="item.id" :href="item.url" target="_blank"
                     style="padding-left:10px;">{{ item.name }}</el-link>
         </span>
        </el-col>
      </div>
    </div>

    <div class="detail-card" v-if="whetherInspectionReportFlag">
      <div class="card-tit">报检信息</div>
      <div class="card-bot">
        <el-col style="line-height: 36px" :span="3" v-if="(memberCode=== 'fsd' || memberCode=== 'mom' || memberCode === 'ydhb') && inspectionObject">
          <span class="bot-ti" style="font-weight: bold;font-size: 15px">报检对象：{{ inspectionObject }}</span>
        </el-col>
        <el-col :span="6" v-if="reportItemViewList.length > 0">
          <el-pagination
            @current-change="handleCurrentChange"
            :page-size="1"
            layout="prev, pager, next"
            :total="reportItemViewList.length">
          </el-pagination>
        </el-col>
      </div>
      <div style="width: 100%;padding: 20px;padding-top: 0">
        <div class="baojian-container" v-if="reportItemViewList.length > 0" style="width: 100%;overflow-x: auto">
          <div style="min-width: 90px">
            <div class="divItem">项目</div>
            <div class="divItem divItemS">报检值</div>
          </div>
          <div class="content1" v-for="bjItem in reportItemViewList[currentPageIndex - 1].msg">
            <div class="divItem" style="position:relative;">{{ bjItem.inspectionEntryName }}</div>
            <div class="divItem divItemSW" v-if="type === 'view' || bjItem.inspectionEntryName === '焊缝编号' || bjItem.inspectionEntryName === '筒节编号'">
              <img v-if="bjItem.measuredValue && bjItem.measuredValue.includes('http')" :src="bjItem.measuredValue" style="width: 20px; height: 20px;">
             <span v-else>{{
                bjItem.measuredValue ?
                  (bjItem.measuredValue.includes("[") ? JSON.parse(bjItem.measuredValue).join(',') : bjItem.measuredValue) : '-'
               }}
              </span> 
            </div>
            <div class="divItem divItemSW" v-else>
              <el-input-number v-if="bjItem.parameterType === 0" v-model="bjItem.measuredValue" controls-position="right"
                              style="width:100%;"/>
            <el-input v-if="bjItem.parameterType === 1" v-model="bjItem.measuredValue"/>
            <template v-if="bjItem.parameterType === 2">
              <el-select v-if="bjItem.measuredValue.includes('http')" v-model="bjItem.measuredValue" class="img-select" style="width:100%;">
                  <!-- 自定义选中后的前缀图标 -->
              <template #prefix>
                <img
                  v-if="bjItem.measuredValue"
                  :src="bjItem.measuredValue"
                  style="width: 20px; height: 20px; margin-right: 8px;"
                />
              </template>
              <el-option v-for="val in JSON.parse(bjItem.possibleValue)" :key="val" :value="val" :label="''">
                      <div style="display: flex; align-items: center; gap: 8px;">
                  <img :src="val" style="width: 20px; height: 20px;">
                </div>
                </el-option>
            </el-select>
            <el-select v-else v-model="bjItem.measuredValue">
              <el-option v-for="val in JSON.parse(bjItem.possibleValue)" :key="val" :label="val" :value="val"/>
            </el-select>
              </template>
            <el-select v-model="bjItem.measuredValue" multiple v-if="bjItem.parameterType === 3">
              <el-option v-for="val in JSON.parse(bjItem.possibleValue)" :key="val" :label="val" :value="val"/>
            </el-select>
            <el-select v-if="bjItem.parameterType === 4" v-model="bjItem.measuredValue" clearable>
              <el-option v-for="val in resultList" :key="val.id" :label="val.name" :value="val.id"/>
            </el-select>
            <el-date-picker
              v-if="bjItem.parameterType === 6"
              v-model="bjItem.measuredValue"
              clearable
              type="datetime"
              value-format="yyyy-MM-dd HH:mm:ss"
              placeholder="时间"
            />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="detail-card">
      <el-tabs v-model="activeTabName" class="detail-tabs" type="border-card">
        <el-tab-pane label="按检验对象" name="按检验对象">
          <div style="width: 100%;padding: 20px;padding-top: 0">
            <div class="baojian-container" v-if="allViewList.length > 0" style="width: 80%">
              <div style="width: 120px">
                <div class="divItem">焊缝号</div>
                <div class="divItem divItemS">探伤合格状态</div>
              </div>
              <div class="content1" v-for="bjItem in allViewList">
                <div class="divItem" style="position:relative;">{{ bjItem.inspectionObjectNumber }}</div>
                <div class="divItem divItemSW" >
                <span v-if="type === 'view'">
                  {{ bjItem.result ? '合格' : '不合格' }}
                  </span>
                    <el-select v-model="bjItem.result" v-if="type === 'edit'">
                    <el-option v-for="val in resultList" :key="val.name" :label="val.name" :value="val.id"/>
                  </el-select>

                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="detail-card" v-if="type !== 'view' && type !== 'edit'">
      <div style="display:flex">
        <div class="card-bot" style="flex-wrap: nowrap;align-items: center;">
          <span class="bot-ti" style="margin-bottom: 0px;">合格状态：</span>
          <el-select v-model="detailData.result">
            <el-option v-for="val in resultList" :key="val.name" :label="val.name" :value="val.id"/>
          </el-select>
        </div>
        <div class="card-bot" style="flex-wrap: nowrap;align-items: center;" v-show="detailData.result === 0">
          <span class="bot-ti" style="margin-bottom: 0px;">处理意见：</span>
          <div>
            <el-input v-model="detailData.suggestion"></el-input>
          </div>
        </div>
      </div>
      <div class="card-bot" style="flex-wrap: nowrap;align-items: center;">
        <span class="bot-ti" style="margin-bottom: 0px;">附件：</span>
        <div class="li-des">
          <el-upload
            ref="upload"
            class="upload-demo"
            :file-list="detailData.attachment.slice(0, textFlg ? detailData.attachment.length : 3)"
            v-bind="taskUploadProps"
            :style="{ width: detailData.attachment.length > 3 ? '140px' : '150px' }"
          >
            <el-button size="small" type="primary">点击上传</el-button>
          </el-upload>
          <div v-show="detailData.attachment.length > 3" class="more" @click="textFlg = !textFlg">
            {{ textFlg ? '收起' : `展开` }}
          </div>
        </div>
      </div>
    </div>
    <div class="order-footer">
      <div/>
      <div class="footer-ri">
        <el-button @click="returnList">{{ type !== 'view' ? '取 消' : '返 回' }}</el-button>
        <el-button @click="batchSave()" type="primary" v-if="type === 'edit'">提 交</el-button>
        <el-button v-if="type !== 'view' && type !== 'edit'" type="primary" @click="save(0)" :loading="loading">暂 存</el-button>
        <el-button v-if="type !== 'view' && type !== 'edit'" type="primary" @click="save(1)" :loading="loading">完成检验</el-button>
      </div>
    </div>
    <div class="lines"/>
    <!-- 现场图片 -->
    <MDialog v-model="pictureVisible" title="现场图片" width="600px" height="500px" :show-footer="false"
             @onOk="pictureVisible = false">
      <el-image
        v-for="item in formatArr(imgList)"
        :key="item"
        style="padding-left: 10px"
        :src="item"
        class="table-row-img"
        :preview-src-list="formatArr(imgList)"
      />
    </MDialog>
  </div>
</template>
<script>
import ProcessFlow from '@/components/ProcessFlow/ProcessFlow'
import {columnsDetail} from './columns'
import {qualityTaskStatus} from '@/config/options.config'
import viewMaterialsDetail from '@/mixin/view-materials-detail'
import saveAs from 'file-saver'
import singleProcessFlow from '@/components/singleProcessFlow/singleProcessFlow'
import api from '@/api/qualityManagement/quality-task'
import {getToken} from '@/utils/auth'

export default {
  name: 'QualityTaskDetail1',
  components: {singleProcessFlow, ProcessFlow},
  mixins: [viewMaterialsDetail],
  filters: {
    fixValue(val) {
      if (val) {
        if (val.parameterType === 4) {
          return val.measuredValue === 1 ? '合格' : val.measuredValue === 0 ? '不合格' : '-'
        } else {
          return val.measuredValue ? val.measuredValue : '-'
        }
      }
    },
  },
  data() {
    return {
      memberCode: '',
      whetherInspectionReportFlag: false,
      reportItemViewList: [],
      currentPageIndex: 0,
      inspectionObject: '',
      columnsDetail,
      allWorkTask: [],
      userList: [],
      customTag: [],
      qualityTaskStatus,
      showPlanOrder: false,
      show: false,
      orderList: [],
      detailData: {
        source: 0,
        attachment: []
      },
      imgList: [],
      pictureVisible: false,
      inspectionTypeNames: ['首检', '过程检', '来料检', '出货检','探伤检测'],
      activeName: 'first',
      relationType: 0,
      id: undefined,
      saveButton: false,
      processFlowVersion: {},
      currentProcedureIds: [],
      currentProcedureName: '',
      currentPage: 1,
      projectLength: 0,
      activeTabName: '按检验对象',
      tableData: [],
      tableHeader: {},
      type: '',
      loading: false,
      uploadProps: {
        action: `${process.env.VUE_APP_BASE_API}api/web/v1/basicData/private/file/upload`,
        headers: {token: getToken()},
        showFileList: true,
        onSuccess: this.fileUploadChange,
        onError: this.fileUploadChange,
        onChange: this.fileUploadChange,
        beforeUpload: this.beforeUpload,
        onPreview: this.handlePreview
      },
      taskUploadProps: {
        action: `${process.env.VUE_APP_BASE_API}api/web/v1/basicData/private/file/upload`,
        headers: {token: getToken()},
        showFileList: true,
        onSuccess: this.fileTaskUploadChange,
        onError: this.fileTaskUploadChange,
        onChange: this.fileTaskUploadChange,
        beforeUpload: this.beforeUpload,
        onRemove: this.fileTaskRemove,
        onPreview: this.handlePreview
      },
      rowIndex: -1,
      columIdx: -1,
      sequenceNumberResult: '',
      resultList: [
        {id: 1, name: '合格'},
        {id: 0, name: '不合格'}
      ],
      result: '',
      suggestion: '',
      textFlg: false,
      newprojectList: [],
      projectList: [],
      allViewList:[]
    }
  },
  computed: {
    height() {
      return this.$store.state.app.contentHeight - 289 - 40 - 54 + 29 - 62 - 22
    },
    priorityStatus() {
      return this.$store.state.user.priorityList
    },
    customTableRowHeight() {
      return this.$store.state.app.tableRowHeight
    }
  },
  activated() {
  },
  mounted() {
    this.memberCode = sessionStorage.getItem("memberCode")
    this.type = this.$route.query.type
    if (this.type === 'startCheck') {
      this.startInspection()
    } else {
      this.getOrderDetail(this.$route.query.id)
    }

  },
  methods: {
    handleChange() {
      const flatArray = this.newprojectList.flatMap(item => item.inspectionItemList).flat()
      const inValidate = flatArray.some(items => items.standardValue != null && ((items.measuredValue > (items.upperLimit + items.standardValue)) || (items.measuredValue < (items.standardValue - items.lowerLimit))))
      if (inValidate) {
        this.detailData.result = 0
      } else {
        this.detailData.result = 1
      }
    },
    // 开始质检
    async startInspection() {
      const res = await api.startQualityTask({
        inspectionTaskId: this.$route.query.id
      })
      if (res) {
        this.getOrderDetail(this.$route.query.id)
      }
    },
    async addPart() {
      const res = await api.addPart({
        inspectionTaskId: this.$route.query.id,
        partSort: this.currentPage - 1
      })
      if (res) {
        await this.getTableDetail(this.$route.query.id)
        this.$message.success('添加成功')
      }
    },
    delPart() {
      if (this.projectLength == 1) {
        this.$message.info('至少需要填写一份数据，不可删除')
        return false
      } else {
        let b = this.newprojectList[this.currentPage - 1].inspectionItemList
        const valid = b.some(item => ((item.measuredValue != null && item.measuredValue != '') || (item
          .remark != null && item.remark != '')))
        if (valid) {
          this.$confirm('当前页已有填写内容，确定删除吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(async () => {
            this.checkDelete()
          })
        } else {
          this.checkDelete()
        }
      }
    },
    async checkDelete() {
      const res = await api.deletePart({
        inspectionTaskId: this.$route.query.id,
        partSort: this.currentPage - 1
      })
      if (res) {
        if (this.currentPage == this.projectLength) {
          this.currentPage--
        }
        this.newprojectList.splice(this.currentPage - 1, 1)
        this.projectLength = this.newprojectList.length
        this.$message.success('删除成功')
      }
    },
    getRowIndex(idx) {
      this.rowIndex = idx
    },
    beforeUpload(file) {
      const isLt20M = file.size / 1024 / 1024 < 20
      if (!isLt20M) {
        this.$message.error('上传文件大小不能超过 20M!')
      }
      return isLt20M
    },
    fileRemove(file, rowIndex) {
      const datas = this.newprojectList[this.currentPage - 1].inspectionItemList[rowIndex].attachments
      this.newprojectList[this.currentPage - 1].inspectionItemList[rowIndex].attachments = datas.filter(item => item.uid !== file.uid)

    },
    // 上传附件
    fileUploadChange(res, file) {
      console.log('ceshiimg', res)
      if (res.errorCode === 0) {
        const list = this.newprojectList[this.currentPage - 1].inspectionItemList[this.rowIndex].attachments ? this.newprojectList[this.currentPage - 1].inspectionItemList[this.rowIndex].attachments : []
        list.push({
          uid: file.uid,
          id: file.uid,
          name: file.name,
          url: res.result
        })
        this.newprojectList[this.currentPage - 1].inspectionItemList[this.rowIndex].attachments = list

        return this.$message.success('上传成功')
      }
    },
    // 上传任务附件
    fileTaskUploadChange(res, file) {
      console.log('ceshiimg', res)
      if (res.errorCode === 0) {
        const list = this.detailData.attachment ? this.detailData.attachment : []
        list.push({
          uid: file.uid,
          id: file.uid,
          name: file.name,
          url: res.result
        })
        this.detailData.attachment = list

        return this.$message.success('上传成功')
      }
    },
    fileTaskRemove(file) {
      const index = this.detailData.attachment.findIndex(item => item.id === file.id)
      this.detailData.attachment.splice(index, 1)
    },
    async save(type) {
      // type 1是完成，0是暂存
      if (type && this.detailData.result != 0 && this.detailData.result != 1) {
        return this.$message.info('合格状态不能为空！')
      }
      this.loading = true
      let obj = {
        inspectionTaskId: this.$route.query.id,
        result: this.detailData.result,
        suggestion: this.detailData.suggestion,
        attachment: this.detailData.attachment.length > 0 ? JSON.stringify(this.detailData.attachment) : '',
        itemList: this.newprojectList.map((item, index) => this.fixArr(this.newprojectList[index]))
      }
      let apis
      apis = !type ? api.saveQualityTask : api.finishQualityTask
      const res = await apis(obj)
      if (res) {
        this.loading = false
        this.$router.back()
      }
      this.loading = false
    },
    fixResult(val, val2) {
      let currentData = val.find((item) => item.inspectionGroupName === val2.inspectionGroupName && item.inspectionItemName === val2.inspectionItemName).measuredValue
      return currentData || currentData === 0 ? currentData : '-'
    },
    fixData(row) {
      if (row.parameterType === 4) {
        return row.measuredValue == 0 ? true : false
      } else if (row.parameterType === 0) {
        return (row.standardValue != null && row.measuredValue) ? row.measuredValue > (row.upperLimit + row.standardValue) || row.measuredValue < (row.standardValue - row.lowerLimit) ? true : '' : ''
      } else {
        return false
      }
    },
    formatArr(arr) {
      try {
        return JSON.parse(arr).map(item => {
          return item.url
        })
      } catch (err) {
        return []
      }
    },
    beforePage() {
      if (this.currentPage > 1) {
        this.currentPage--
      }
    },
    nextPage() {
      if (this.currentPage < this.projectLength) {
        this.currentPage++
      }
    },
    viewPicture(row) {
      this.imgList = row.attachments
      this.pictureVisible = true
    },
    handlePreview(file) {
      if (file.url.indexOf('.txt') > 0) {
        saveAs(file.url, '文件')
        return
      }
      window.open(file.url)
    },
    fixArr(val) {
      let arr = val.inspectionItemList.map(item => {
        return {
          inspectionTaskItemId: item.inspectionTaskItemId,
          measuredValue: item.measuredValue,
          attachments: item.attachments.length > 0 ? JSON.stringify(item.attachments) : '',
          remark: item.remark
        }
      })

      return arr
    },
    fixProduceData(val) {
      const list = val.map(item => item.procedureName)
      return list.lenght === 1 ? list[0] : list.join('-')
    },
    // 订单详情
    async getOrderDetail(id) {
      const res = await api.getQualityTaskDetail({inspectionTaskId: id})
      if (res) {
        this.projectLength = Object.keys(res.itemList).length
        this.detailData = {
          ...res,
          attachment: res.attachment ? JSON.parse(res.attachment) : []
        }
        this.fixTableData(res.itemList)
        this.tableHeader = res.tableHead
        this.tableData = res.tableEntry
        //处理报检信息 ————————Start————————
        this.whetherInspectionReportFlag = Boolean(res.whetherInspectionReport)
        // this.whetherInspectionReportFlag = true

        this.inspectionObject = res.inspectionObject
        if (res.reportItemViewList) {
          const y = []
          for (const resKey in res.reportItemViewList) {
            y.push({msg: res.reportItemViewList[resKey]})
          }
          this.reportItemViewList = y.map(item =>{
            return {
              ...item,
              msg:item.msg.map(items =>{
                return {
                  ...items,
                  measuredValue: items.parameterType === 3 && items.measuredValue && this.type!='view' ? items.measuredValue.split('、') : items.parameterType === 4 && items.measuredValue ? +items.measuredValue : items.measuredValue
                }
              })
            }
          })
          if (y.length) {
            this.currentPageIndex = 1
          }
          console.log('报检详细信息', this.reportItemViewList)
        }
        if(res.flawItemViewList && res.flawItemViewList.length > 0){
          this.allViewList = res.flawItemViewList
          console.log('缺陷详细信息', this.allViewList)
        }
        //处理报检信息 ————————End————————
      }
    },
    // 检验对象详情
    async getTableDetail(id) {
      const res = await api.getQualityTaskDetail({inspectionTaskId: id})
      if (res) {
        this.projectLength = Object.keys(res.itemList).length
        this.fixTableData(res.itemList)
      }
    },
    fixTableData(val) {
      this.newprojectList = Object.values(val).map((item, index) => ({
        currentPage: index + 1,
        inspectionItemList: item.map(items => {
          return {
            ...items,
            measuredValue: items.parameterType === 3 && items.measuredValue ? items.measuredValue.split(',') : items.parameterType === 4 && items.measuredValue ? +items.measuredValue : items.measuredValue,
            possibleValue: [2, 3].includes(items.parameterType) ? JSON.parse(items.possibleValue) : [],
            attachments: this.type === 'view' ? items.attachments : items.attachments ? JSON.parse(items.attachments) : []
          }
        })
      }))
    },
    returnList() {
      this.$router.back()
    },
    handleCurrentChange(val) {
      this.currentPageIndex = val
    },
    async batchSave() {
      const mergedResults = this.reportItemViewList.flatMap(item => item.msg)
      let obj = {
        inspectionTaskId:this.$route.query.id,
        flawList:this.allViewList.map(item => {
          return {
            flawItemId:item.id,
            result: item.result
          }
        }),
        reportList:mergedResults.map(item => {
          return {
            reportItemId:item.id,
            measuredValue: item.parameterType === 3 && item.measuredValue != null ? item.measuredValue.join('、') : item.measuredValue
          }
        })
      }
      const res = await api.updateReportItemList(obj)
      if(res){
        this.$message.success('保存成功')
        this.$router.back()
      }
    }
  }
}
</script>

<style scoped lang="scss">
.baojian-container {
  width: 100%;
  max-height: 568px;
  border: 1px solid #ebeef5;
  margin-top: 2px;
  display: flex;
  justify-content: flex-start;

  .edd {
    position: absolute;
    right: 4px;
    top: 0;
    height: 100%;
    display: flex;
    align-items: center;
  }

  .divItem {
    color: #909399;
    border-right: 1px solid #EBEEF5;
    border-bottom: 1px solid #EBEEF5;
    padding: 4px;
    background: #F6F7F9;
    line-height: 23px;
    font-weight: bold;
    font-size: 14px;
  }

  .divItemS {
    color: #606266;
    font-weight: normal;
    height: calc(100% - 32px);
    display: flex;
    align-items: center;
  }

  .divItemSW {
    color: #606266;
    font-weight: normal;
    background: white;
    height: calc(100% - 32px);
    display: flex;
    align-items: center;
  }

  .content1 {
    flex: auto;
    min-width: 150px;
    box-sizing: border-box;
  }

}

.red-group {
  ::v-deep {
    .el-input__inner {
      color: #e02020 !important;
    }
  }
}

::v-deep {
  .table-row-img {
    > img {
      width: 100px;
    }
  }
}
::v-deep {
  .img-select .el-input {
    font-size:0
  }
}
.bot-li {
  display: flex;
  justify-content: flex-start;

  .bot-ti {
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #393d60;
    display: inline-block;
    margin-bottom: 15px;
  }
}

.el-tags {
  width: 100px;
  text-overflow: ellipsis;
  overflow: hidden;
}

.icon-img {
  position: absolute;
  right: 0;
  top: 0;
  width: 19px;
  height: 19px;
  display: block;
}

.all-ipt {
  margin-top: 4px;
}

.procedure-detail-header {
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #41456b;
  line-height: 20px;
  margin-bottom: 12px;
  cursor: pointer;

  .left {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  img {
    width: 8px;
    height: 12px;
    display: block;
    margin-right: 10px;
  }
}

.detail-card {
  margin-bottom: 20px;
}

.card-bot {
  display: flex;
  flex-flow: row wrap;

  .bot-ti {
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #393d60;
    display: inline-block;
    margin-bottom: 16px;
  }

  .li-des {
    position: relative;

    .more {
      // margin: 0 0 0 110px;
      cursor: pointer;
      width: 45px;
      height: 20px;
      background: #ebeef5;
      text-align: center;
      border-radius: 5px;
      margin: 10px auto;
      line-height: 20px;
      position: absolute;
      top: 32px;
      right: -60px;
    }
  }
}

.order-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  height: 60px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
  background: #fff;
  border-top: 1px solid #dde3eb;

  .footer-le {
    padding-left: 30px;
    height: 20px;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #393d60;

    span {
      margin-right: 45px;
      display: inline-block;
    }
  }

  .footer-ri {
    margin-right: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.lines {
  width: 100%;
  height: 30px;
  background: #f0f2f5;
}
</style>
